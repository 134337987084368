<template>
  <div class="bk-page" id="UseTrace">
    <div class="bk-page-scroll">
      <div class="bk-page-lst">
        <Form :label-width="80" @submit.native.prevent class="bk-page-search" inline>
          <FormItem label="状态">
            <Select v-model="searchData1.Status">
              <Option
                :key="item.key"
                :value="item.key"
                v-for="item in $designConst.equipmentUseTrace.StatusLst"
              >{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="实验室">
            <Select clearable v-model="searchData1.LabId">
              <Option :key="item.Id" :value="item.Id" v-for="item in LabLst">{{item.Name}}</Option>
            </Select>
          </FormItem>
          <FormItem class="btns" style="width: 220px">
            <Button @click="onSearch" type="primary">搜索</Button>
            <Button @click="reSearchData" type="default">重置</Button>
            <Button @click="toAdd" ghost type="primary" v-if="$able.map.EquUseAdd">
              <Icon type="md-add" />申请
            </Button>
          </FormItem>
        </Form>
        <Table :columns="tableCols" :data="tableData" :loading="tableLoading"></Table>
        <Page
          :current="pageIndex"
          :page-size="pageSize"
          :total="recordCount"
          @on-change="(index) => onChangePage(index, 0)"
          @on-page-size-change="(size) => onChangePage(1, size)"
          show-elevator
          show-sizer
          show-total
          transfer
        />
      </div>
    </div>
    <edit @on-add="onChangePage(1)" @on-upd="reqTable" ref="edit" />
    <dtl @on-upd="reqTable" ref="dtl" />
  </div>
</template>
<script>
// 申请人提交申请、编辑；有权限的人结束使用。
import edit from "./use-trace-edit";
// 作查看、审核
import dtl from "./use-trace-dtl.vue";
import SelectLabId from "@/components/select-labid.js";
export default {
  components: { edit, dtl },
  mixins: [SelectLabId],
  data() {
    return {
      searchData1: {},
      searchData2: {},
      // ——————————
      tableLoading: false,
      tableCols: [
        {
          title: "状态",
          key: "Status",
          width: 100,
          render: (h, params) => {
            return h("Badge", {
              props: {
                status:
                  this.$designConst.equipmentUseTrace.StatusBadge[
                    params.row.Status
                  ],
                text: this.$designConst.equipmentUseTrace.Status[
                  params.row.Status
                ],
              },
            });
          },
        },
        { title: "实验室", key: "LabName", width: 70 },
        {
          title: "仪器",
          minWidth: 120,
          render: (h, params) => {
            return [
              h(
                "span",
                {
                  style: { marginRight: "5px" },
                },
                params.row.EquipmentName
              ),
              h(
                "span",
                {
                  attrs: { class: "text-sub text-xs" },
                  style: { whiteSpace: "nowrap" },
                },
                params.row.LabName
              ),
              h(
                "div",
                {
                  attrs: { class: "text-sub text-xs" },
                },
                params.row.ModeNo
              ),
              h(
                "div",
                {
                  attrs: { class: "text-sub text-xs" },
                },
                params.row.EquipmentCode
              ),
            ];
          },
        },
        {
          title: "委托单",
          render: (h, params) =>
            h("div", { attrs: { class: "text-xs" } }, params.row.OrderCode),
          width: 125,
        },
        { title: "项目", key: "ItemName", minWidth: 100 },
        // { title: "申请时间", key: "ApplyTime" },
        { title: "使用人", key: "UseManName", width: 80 },
        {
          title: "使用时间",
          render: (h, params) => h("div", { attrs: { class: "text-xs" } }, params.row.UseTime.substring(0, 16)),
          width: 125,
        },
        {
          title: "审核人/时间",
          key: "",
          minWidth: 150,
          render: (h, params) => {
            return [
              h(
                "span",
                {
                  style: { marginRight: "5px" },
                },
                params.row.CheckUserName
              ),
              h(
                "span",
                {
                  attrs: { class: "text-sub text-xs" },
                  style: { whiteSpace: "nowrap" },
                },
                params.row.CheckTime
              ),
            ];
          },
        },
        {
          title: "操作",
          width: 120,
          align: "right",
          render: (h, params) => {
            let a = [];
            let isEditor =
              this.$store.state.user.Id == params.row.CreateUserId ||
              this.$store.state.user.Id == params.row.UseManId;
            let reviewABLE =
              this.$able.map.EquUseReview && params.row.Status == 1;
            let editABLE =
              this.$able.map.EquUseUpd &&
              [3, 98, 99].indexOf(params.row.Status) == -1;
            if (reviewABLE) {
              a.push(
                h(
                  "span",
                  {
                    style: { cursor: "pointer", color: "#2d8cf0" },
                    on: {
                      click: () => {
                        this.$refs.dtl.open(params.row.Id);
                      },
                    },
                  },
                  "审核"
                )
              );
              a.push(h("Divider", { props: { type: "vertical" } }));
            }
            if (!reviewABLE || editABLE) {
              a.push(
                h("Icon", {
                  props: { type: editABLE ? "md-create" : "md-open", size: 18 },
                  attrs: { title: editABLE ? "编辑" : "详情" },
                  style: { cursor: "pointer", color: "#2d8cf0" },
                  on: {
                    click: () => {
                      editABLE
                        ? this.$refs.edit.open(params.row.Id)
                        : this.$refs.dtl.open(params.row.Id);
                    },
                  },
                })
              );
              a.push(h("Divider", { props: { type: "vertical" } }));
            }
            if (this.$able.map.EquUseDel) {
              a.push(
                h("Icon", {
                  props: { type: "md-close", size: 18 },
                  attrs: { title: "删除" },
                  style: { cursor: "pointer", color: "#ed4014" },
                  on: {
                    click: () => {
                      this.toDel(params.row);
                    },
                  },
                })
              );
            }
            return a;
          },
        },
      ],
      tableData: [],
      // ——————————
      recordCount: 0,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  activated: function () {
    console.info("激活：", this.$tab.conf[this.$route.name].label);
    this.$root.$off("on-refresh");
    this.$root.$on("on-refresh", () => {
      this.init();
    });

    if (this.$route.params.re == 1) {
      this.init();
    }
  },
  methods: {
    init() {
      this.reqLab();
      if (window.toTabData) {
        if (window.toTabData.action == "toUseEnd") {
          if (this.$refs.edit.flag) {
            this.$refs.edit.clear();
          }
          this.$refs.edit.open(window.toTabData.Id);
          window.toTabData = null;
        } else if (window.toTabData.action == "toEquipmentUseApply") {
          if (this.$refs.edit.flag) {
            this.$refs.edit.clear();
          }
          this.$refs.edit.formd.OrderId = window.toTabData.OrderId * 1;
          this.$refs.edit.formd.OrderCode = window.toTabData.OrderCode;
          this.$refs.edit.formd.ItemId = window.toTabData.ItemId * 1;
          this.$refs.edit.reqItems();
          this.$refs.edit.reqEquipment();
          this.$refs.edit.open();
          window.toTabData = null;
        }
      } else if (this.$refs.edit.flag) {
        this.$refs.edit.close();
      }
      this.initSearch();
    },
    initSearch() {
      this.searchData1 = {};
      this.pageIndex = 1;
      this.onSearch();
    },
    onSearch() {
      let data = this.$util.copy(this.searchData1);
      this.searchData2 = data;
      this.pageIndex = 1;
      this.reqTable();
    },
    reSearchData() {
      this.searchData1 = {};
    },
    reqTable() {
      if (this.$able.map.EquUseQry == undefined) {
        this.$Message.error("无权限查询");
        return;
      }
      let params = Object.assign(
        {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        },
        this.searchData2
      );
      this.tableLoading = true;
      this.$axios
        .get("/Api/BtmsEquipmentUseTrace/QueryPg", { params: params })
        .then((res) => {
          this.tableLoading = false;
          if (res.result) {
            this.recordCount = res.data.RecordCount;
            this.tableData = res.data.DataSet || [];
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pageIndex = index;
      }
      if (size > 0) {
        this.pageSize = size;
      }
      this.reqTable();
    },
    // ==========
    toAdd() {
      this.$refs.edit.open();
    },
    toDel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除",
        onOk: () => {
          this.doDel(row.Id);
        },
      });
    },
    doDel(id) {
      this.$axios
        .post("/Api/BtmsEquipmentUseTrace/Delete", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("删除成功");
            this.reqTable();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>