<template>
  <div id="equipmentEdit">
    <Drawer
      :mask-closable="false"
      :transfer="false"
      @on-close="close"
      class="bk-sub-drawer equipment-use-edit-drawer"
      v-model="flag"
      width="600"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="ivu-drawer-header-inner" slot="header">
        <span>{{ id > 0 ? "详情" : "仪器使用申请" }}</span>
        <template v-if="id > 0">
          <Divider type="vertical" />
          <span class="text-sub">
            {{
            $designConst.equipmentUseTrace.Status[formd.Status]
            }}
          </span>
        </template>
      </div>
      <div class="bk-drawer-body">
        <Form
          :label-width="110"
          :model="formd"
          :rules="rules"
          @submit.native.prevent
          inline
          ref="form"
        >
          <FormItem label="委托单：" prop="OrderId" required>
            <Input
              :value="formd.OrderCode"
              @on-clear="unPickOrder"
              clearable
              readonly
              v-if="formd.Status < 2"
            >
              <Button @click="toPickOrder" icon="ios-more" slot="append"></Button>
            </Input>
            <div v-if="formd.Status > 1">{{ formd.OrderCode }}</div>
          </FormItem>
          <FormItem label="测试项目：" prop="ItemId" required>
            <Select :disabled="!formd.OrderId" v-if="formd.Status < 2" v-model="formd.ItemId">
              <Option
                :key="`item-d-${item.Id}`"
                :value="item.Id"
                v-for="item in orderItems"
              >{{ item.Name }}</Option>
            </Select>
            <div v-if="formd.Status > 1">{{ formd.ItemName }}</div>
          </FormItem>
          <FormItem class="long" label="仪器：" prop="EquipmentId" required>
            <div
              v-if="formd.Status > 1"
            >{{formd.EquipmentName}}/{{formd.LabName}}/{{formd.ModeNo}}/{{formd.EquipmentCode}}</div>
            <Select
              :disabled="!formd.OrderId"
              filterable
              v-if="formd.Status < 2"
              v-model="formd.EquipmentId"
            >
              <Option
                :key="item.Id"
                :label="`${item.Name}/${item.LabName}/${item.ModeNo}/${item.Code}`"
                :value="item.Id"
                v-for="item in equipment"
              >
                <div>{{ item.Name }}({{item.LabName}})</div>
                <div class="text-sub">{{ item.ModeNo }}/{{item.Code}}</div>
              </Option>
            </Select>
          </FormItem>
          <FormItem label="申请时间：" v-show="id > 0">{{ formd.ApplyTime }}</FormItem>
          <br />
          <FormItem label="审核人：" v-show="formd.Status > 1">{{ formd.CheckUserName }}</FormItem>
          <FormItem label="审核时间：" v-show="formd.Status > 1">{{ formd.CheckTime }}</FormItem>
          <FormItem label="审核意见：" v-show="formd.Status > 1">{{ formd.CheckOpinion || "无" }}</FormItem>
          <FormItem label="使用时间：" prop="UseTime" required>
            <div v-if="formd.Status > 1">{{ formd.UseTime }}</div>
            <DatePicker
              format="yyyy-MM-dd HH:mm"
              type="datetime"
              v-if="formd.Status < 2"
              v-model="formd.UseTime"
            />
          </FormItem>
          <FormItem label="使用人：" prop="UseManId" required>
            <div v-if="formd.Status > 1">{{ formd.UseManName }}</div>
            <Input
              :value="formd.UseManName"
              @on-clear="unPickUser('useMan')"
              clearable
              readonly
              v-if="formd.Status < 2"
            >
              <Button @click="toPickUser('useMan')" icon="ios-more" slot="append"></Button>
            </Input>
          </FormItem>

          <FormItem class="long" label="使用描述：" prop="UseDescription" required>
            <Input type="textarea" v-if="formd.Status < 98" v-model="formd.UseDescription" />
            <div v-if="formd.Status == 98">{{ formd.UseDescription }}</div>
          </FormItem>
          <FormItem class="long" label="使用前情况：" prop="BeforeUseDescr" required>
            <Input type="textarea" v-if="formd.Status < 2" v-model="formd.BeforeUseDescr" />
            <div v-if="formd.Status > 1">{{ formd.BeforeUseDescr }}</div>
          </FormItem>

          <FormItem label="使用结束时间：" prop="UseEndTime" v-show="formd.Status >= 4">
            <div v-if="formd.Status == 98">{{ formd.UseEndTime }}</div>
            <DatePicker
              format="yyyy-MM-dd HH:mm"
              type="datetime"
              v-if="formd.Status == 4"
              v-model="formd.UseEndTime"
            />
          </FormItem>
          <FormItem class="long" label="使用后情况：" prop="AfterUseDescr" v-show="formd.Status >= 4">
            <Input type="textarea" v-model="formd.AfterUseDescr" />
          </FormItem>
          <FormItem class="long" label="异常处理结果：" prop="ResultDescr" v-show="formd.Status >= 4">
            <Input type="textarea" v-model="formd.ResultDescr" />
          </FormItem>

          <FormItem class="long" label="备注：">
            <Input type="textarea" v-model="formd.Remark" />
          </FormItem>
        </Form>
      </div>
      <div class="bk-drawer-footer">
        <Button @click="ok('end')" type="error" v-if="formd.Status == 4">结束使用</Button>
        <Button
          :loading="posting"
          @click="ok('save')"
          type="primary"
        >{{ this.id > 0 ? "保存" : "提交" }}</Button>
      </div>
    </Drawer>
    <pick-order @on-ok="onPickOrder" ref="PickOrder" />
    <pick-user @on-ok="onPickUser" ref="PickUser" type="1" />
  </div>
</template>
<script>
import PickOrder from "../../components/PickOrder.vue";
import PickUser from "../../components/PickUser.vue";
export default {
  components: { PickOrder, PickUser },
  data() {
    return {
      // ——————————
      flag: false,
      id: 0,
      formd: {},
      rules: {
        EquipmentId: [{ required: true, type: "number", message: " " }],
        OrderId: [{ required: true, type: "number", message: " " }],
        ItemId: [{ required: true, type: "number", message: " " }],
        UseManId: [{ required: true, type: "number", message: " " }],
        UseTime: [
          {
            validator: (rule, value, callback) => {
              this.formd.UseTime ? callback() : callback(new Error(" "));
            },
          },
        ],
        UseDescription: [{ required: true, message: " " }],
        BeforeUseDescr: [{ required: true, message: " " }],
        UseEndTime: [
          {
            validator: (rule, value, callback) => {
              if (this.okAction == "end") {
                this.formd.UseEndTime ? callback() : callback(new Error(" "));
              } else {
                callback();
              }
            },
          },
        ],
        AfterUseDescr: [
          {
            validator: (rule, value, callback) => {
              if (this.okAction == "end") {
                this.formd.AfterUseDescr
                  ? callback()
                  : callback(new Error(" "));
              } else {
                callback();
              }
            },
          },
        ],
        ResultDescr: [
          {
            validator: (rule, value, callback) => {
              if (this.okAction == "end") {
                this.formd.ResultDescr ? callback() : callback(new Error(" "));
              } else {
                callback();
              }
            },
          },
        ],
      },
      okAction: "",
      loading: false,
      failed: false,
      posting: false,
      equipment: [],
      orderItems: [],
      action: "",
    };
  },
  methods: {
    open(id) {
      this.flag = true;
      if (id) {
        this.id = id;
        this.req();
      } else {
        this.$set(this.formd, "Status", 1);
        this.$set(this.formd, "UseTime", new Date());
        this.$set(this.formd, "UseManId", this.$store.state.user.Id * 1);
        this.$set(this.formd, "UseManName", this.$store.state.user.UserName);
      }
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsEquipmentUseTrace/GetVo/" + this.id)
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data;
            this.reqItems();
            this.reqEquipment();
            // this.IsEditor = this.$store.state.user.Id == this.formd.CreateUserId || this.$store.state.user.Id == this.formd.UseManId、
          } else {
            this.failed = true;
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    close() {
      this.flag = false;
      this.clear();
    },
    clear() {
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = {};
      this.id = 0;
    },
    ok(action) {
      this.okAction = action;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      this.posting = true;
      let api =
        this.id > 0
          ? "/Api/BtmsEquipmentUseTrace/Update"
          : "/Api/BtmsEquipmentUseTrace/Add";
      let param = this.$util.copy(this.formd);
      if (this.okAction == "end") {
        param.Status = 98;
      }
      if (this.formd.UseTime != null && typeof this.formd.UseTime == "object") {
        param.UseTime = this.formd.UseTime.format("yyyy-MM-dd hh:mm");
      }
      if (
        this.formd.UseEndTime != null &&
        typeof this.formd.UseEndTime == "object"
      ) {
        param.UseEndTime = this.formd.UseEndTime.format("yyyy-MM-dd hh:mm");
      }
      this.$axios
        .post(api, param)
        .then((res) => {
          if (res.result) {
            this.$Message.success(this.id > 0 ? "编辑成功" : "新增成功");
            this.$emit(this.id > 0 ? "on-upd" : "on-add");
            this.close();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
          this.posting = false;
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    // ==========
    reqEquipment() {
      this.$axios
        .get("/Api/BtmsEquipment/QueryPg", {
          params: { PageSize: 99, LabId: this.formd.LabId },
        })
        .then((res) => {
          if (res.result) {
            this.equipment = res.data.DataSet;
          }
        });
    },
    // 选择委托单
    toPickOrder() {
      if (this.formd.OrderId) {
        this.$refs.PickOrder.open([
          {
            Id: this.formd.OrderId,
            Code: this.formd.OrderCode,
            LabId: this.formd.LabId,
          },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      this.unPickOrder();
      if (lst[0]) {
        setTimeout(() => {
          this.formd.OrderId = lst[0].Id;
          this.$set(this.formd, "OrderCode", lst[0].Code);
          this.reqItems();
          this.formd.EquipmentId = lst[0].EquipmentId;
          this.$set(this.formd, "EquipmentName", lst[0].EquipmentName);
          this.$set(this.formd, "LabId", lst[0].LabId);
          this.reqEquipment();
        }, 300);
      }
    },
    unPickOrder() {
      this.formd.OrderId = "";
      this.$set(this.formd, "OrderCode", "");
      this.orderItems = [];
      this.formd.ItemId = 0;
      this.formd.EquipmentId = 0;
      this.$set(this.formd, "EquipmentName", "");
      this.equipment = [];
      this.$set(this.formd, "LabId", 0);
    },
    // 测试项目
    reqItems() {
      this.$axios
        .get("/Api/BtmsOrderItems/QueryPg", {
          params: {
            PageIndex: 1,
            PageSize: 99,
            OrderId: this.formd.OrderId,
          },
        })
        .then((res) => {
          if (res.result) {
            this.orderItems = res.data.DataSet;
          }
        });
    },
    // 选择人员
    toPickUser(action) {
      this.action = action;
      let def = [];
      if (this.action == "useMan") {
        if (this.formd.UseManId) {
          def = [
            {
              Id: this.formd.UseManId,
              UserName: this.formd.UseManName,
            },
          ];
        }
      } else if (this.action == "checkUser") {
        if (this.formd.CheckUserId) {
          def = [
            {
              Id: this.formd.CheckUserId,
              UserName: this.formd.CheckUserName,
            },
          ];
        }
      }

      this.$refs.PickUser.open(def);
    },
    onPickUser(lst) {
      if (this.action == "useMan") {
        if (lst[0]) {
          this.formd.UseManId = lst[0].Id;
          this.$set(this.formd, "UseManName", lst[0].UserName);
        } else {
          this.formd.UseManId = "";
          this.$set(this.formd, "UseManName", "");
        }
      } else if (this.action == "checkUser") {
        if (lst[0]) {
          this.formd.CheckUserId = lst[0].Id;
          this.$set(this.formd, "CheckUserName", lst[0].UserName);
        } else {
          this.formd.CheckUserId = "";
          this.$set(this.formd, "CheckUserName", "");
        }
      }
    },
    unPickUser() {
      if (this.action == "useMan") {
        this.formd.UseManId = "";
        this.$set(this.formd, "UseManName", "");
      } else if (this.action == "checkUser") {
        this.formd.CheckUserId = "";
        this.$set(this.formd, "CheckUserName", "");
      }
    },
  },
};
</script>
<style lang="less">
.equipment-use-edit-drawer {
  .ivu-form-item {
    width: 50%;
    margin-right: 0;
  }
  .ivu-form-item.long {
    width: 100%;
  }
}
</style>