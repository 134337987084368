<template>
  <div id="equipmentDtl">
    <Drawer
      :mask-closable="false"
      :transfer="false"
      @on-close="close"
      class="bk-sub-drawer equipment-use-dtl-drawer"
      title="详情"
      v-model="flag"
      width="600"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="bk-drawer-body">
        <Form :label-width="120" @submit.native.prevent inline>
          <FormItem
            label="仪器："
          >{{formd.EquipmentName}}/{{formd.LabName}}/{{formd.ModeNo}}/{{formd.EquipmentCode}}</FormItem>
          <br />
          <FormItem label="委托单：">{{ formd.OrderCode }}</FormItem>
          <FormItem label="测试项目：">{{ formd.ItemName }}</FormItem>
          <FormItem label="申请时间：">{{ formd.ApplyTime }}</FormItem>
          <FormItem label="状态：">{{ $designConst.equipmentUseTrace.Status[formd.Status] }}</FormItem>
          <FormItem label="审核人：" v-show="formd.Status > 1">{{ formd.CheckUserName }}</FormItem>
          <FormItem label="审核时间：" v-show="formd.Status > 1">{{ formd.CheckTime }}</FormItem>
          <FormItem
            class="long"
            label="审核意见："
            v-show="formd.Status > 1"
          >{{ formd.CheckOpinion || "无" }}</FormItem>
          <FormItem label="使用人：">{{ formd.UseManName }}</FormItem>
          <FormItem label="使用时间：">{{ formd.UseTime }}</FormItem>
          <FormItem label="使用结束时间：" v-show="formd.UseEndTime">{{ formd.UseEndTime }}</FormItem>
          <FormItem class="long" label="使用描述：">{{ formd.UseDescription }}</FormItem>
          <FormItem class="long" label="使用前情况：">{{ formd.BeforeUseDescr }}</FormItem>
          <FormItem class="long" label="使用后情况：" v-if="formd.AfterUseDescr">{{ formd.AfterUseDescr }}</FormItem>
          <FormItem class="long" label="异常处理结果：" v-show="formd.ResultDescr">{{ formd.ResultDescr }}</FormItem>
          <FormItem class="long" label="备注：">{{ formd.Remark || "无" }}</FormItem>
        </Form>

        <Form :label-width="100" @submit.native.prevent inline v-if="formd.Status == 1">
          <FormItem class="long" label="审核意见：">
            <Input type="textarea" v-model="checkObj.Opinion" />
          </FormItem>
        </Form>
      </div>
      <div class="bk-drawer-footer" v-if="formd.Status == 1">
        <Button
          :disabled="posting"
          :loading="posting && checkObj.Status == 1"
          @click="check(1)"
          type="primary"
        >同意</Button>
        <Button
          :disabled="posting"
          :loading="posting && checkObj.Status == 2"
          @click="check(2)"
          type="error"
        >不同意</Button>
      </div>
    </Drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flag: false,
      id: 0,
      formd: {},
      loading: false,
      failed: false,
      posting: false,
      checkObj: {},
    };
  },
  methods: {
    open(id) {
      this.flag = true;
      this.id = id;
      this.req();
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsEquipmentUseTrace/GetVo/" + this.id)
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data;
          } else {
            this.failed = true;
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    close() {
      this.flag = false;
      this.clear();
    },
    clear() {
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.formd = {};
      this.checkObj = {};
      this.id = 0;
    },
    check(status) {
      if (status == 2 && !this.checkObj.Opinion) {
        this.$Message.warning("请输入审核意见");
        return;
      }
      this.checkObj.BillId = this.formd.Id;
      this.checkObj.Status = status;
      this.posting = true;
      this.$axios
        .post("/Api/BtmsEquipmentUseTrace/Review", this.checkObj)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("操作成功");
            this.req();
            this.$emit("on-upd");
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>
<style lang="less">
.equipment-use-dtl-drawer {
  .ivu-form-item {
    width: 50%;
    margin: 0;
  }
  .ivu-form-item.long {
    width: 100%;
  }
  .ivu-form-item-label {
    color: #808695;
  }
}
</style>